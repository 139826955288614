exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-commercial-tsx": () => import("./../../../src/pages/commercial.tsx" /* webpackChunkName: "component---src-pages-commercial-tsx" */),
  "component---src-pages-contact-mob-tsx": () => import("./../../../src/pages/contact-mob.tsx" /* webpackChunkName: "component---src-pages-contact-mob-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quote-tsx": () => import("./../../../src/pages/quote.tsx" /* webpackChunkName: "component---src-pages-quote-tsx" */),
  "component---src-pages-residential-tsx": () => import("./../../../src/pages/residential.tsx" /* webpackChunkName: "component---src-pages-residential-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-test-quote-tsx": () => import("./../../../src/pages/testQuote.tsx" /* webpackChunkName: "component---src-pages-test-quote-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

